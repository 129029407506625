<template>
  <div class="product-details" v-if="fetched && data">
    <div class="vx-row mb-base">
      <div class="vx-col lg:w-1/2 w-full">
        <vx-card title="Demoshop Details" class="h-full">
          <div class="vx-row">
            <div class="vx-col lg:w-full w-full">
              <div class="vx-row mt-0">
                <div class="vx-col lg:w-full">
                  <vs-checkbox v-model="data.active" class="ml-0">Aktiv</vs-checkbox>
                  <vs-checkbox v-model="data.auto_reminder" class="ml-0">Auto Reminder ist aktiv</vs-checkbox>
                  <vs-checkbox v-model="data.is_internal" class="ml-0">Interner Demoshop</vs-checkbox>

                </div>
              </div>
              <div class="vx-row mt-4">
                <div class="vx-col w-full sm:w-1/2">
                  <vs-input class="w-full" label="Vollständiger Name des Kunden/Partner Ansprechpartner*"
                            data-vv-as="Name"
                            name="full_name"
                            v-model="data.full_name" type="text" v-validate="'required'"/>
                  <span class="text-danger text-sm" v-if="errors.has('full_name')">{{
                      errors.first('full_name')
                    }}</span>
                </div>


                <div class="vx-col w-full mt-4 sm:mt-0 sm:w-1/2">
                  <vs-input class="w-full" label="E-Mail (Mehrere Semikolon) *" data-vv-as="E-Mail (Mehrere Semikolon)"
                            name="email"
                            v-model="data.email" type="text" v-validate="'required'"/>
                  <span class="text-danger text-sm" v-if="errors.has('email')">{{ errors.first('email') }}</span>
                </div>

              </div>


                <div class="vx-col lg:w-full w-full mt-3">
                    <div class="vx-row">
                        <div class="vx-col lg:w-full">
                            <label class="vs-input--label">Zuordnung zu CRM-Deal/Lead:</label>

                            <mg-select v-model="data.crm_deal_id" :options="crmDeals" name="matchcode"
                                       placeholder="Zuordnung zum Lieferant/Kunde"
                                       track-by="id" label="matchcode" data-vv-as="Kunde/Lieferant">
                            </mg-select>
                            <a :href="'https://b2bsellersgmbh.pipedrive.com/deal/'+ data.crm_deal_id" target="_blank" v-if="data.crm_deal_id" class="w-full d-block">CRM-Deal öffnen</a>
                        </div>
                    </div>
                </div>
              <div class="vx-col lg:w-full w-full mt-3">
                <div class="vx-row">
                  <div class="vx-col lg:w-full">
                    <label class="vs-input--label">Domain</label>
                    <vs-input class="vs-input--input normal" type="text" v-validate="'required'" name="domain"
                              v-model="data.domain"></vs-input>
                    <span class="text-danger text-sm" v-if="errors.has('domain')">{{ errors.first('domain') }}</span>
                    <i>Format: XXXXXX.demoshop.b2b-sellers.com</i>

                    <a :href="'https://'+data.domain" title="Zum Demoshop" target="_blank" v-if="data.domain"
                       class="w-full d-block">Demoshop öffnen</a>
                  </div>
                </div>
                <div class="vx-row mt-4">
                  <div class="vx-col lg:w-full">
                    <label class="vs-input--label">Internet Kontakt</label>
                    <vs-input class="vs-input--input normal" type="text" v-validate="'required'"
                              name="internal_contact_id"
                              v-model="data.internal_contact_id"></vs-input>
                    <span class="text-danger text-sm"
                          v-if="errors.has('internal_contact_id')">{{ errors.first('internal_contact_id') }}</span>
                  </div>
                </div>
                <div class="vx-row mt-4">
                  <div class="vx-col lg:w-full">
                    <label class="vs-input--label">Personal Demo Termin (optional)</label><br>

                    <flat-pickr v-model="data.personal_demo_date" :config="datePickerConfig" name="personal_demo_date" :disabled="data.is_internal === 1 || data.is_internal === true"
                                placeholder="Datum auswählen" class="w-full" ></flat-pickr>
                      <small v-if="(data.is_internal === false || data.is_internal === 0) && !data.personal_demo_date">
                          <span class="text-warning">Wenn gefüllt, wird eine Automatisierung gestartet.</span>
                      </small>
                      <div class="float-right" >
                          <span class="pseudo-link d-inline-block mt-2 mr-2" @click="setDemoDateInDays(0)">Heute</span>
                          <span class="pseudo-link d-inline-block mt-2 mr-2" @click="setDemoDateInDays(1)">Morgen</span>
                          <span class="pseudo-link d-inline-block mt-2 mr-2" @click="setDemoDateInDays(2)">Übermorgen</span>
                      </div>
                  </div>
                </div>
                  <div class="vx-row mt-4">
                  <div class="vx-col lg:w-full">
                    <label class="vs-input--label">Verfügbar bis (Kann auch leer bleiben)</label><br>

                    <flat-pickr v-model="data.valid_to" :config="datePickerConfig" name="valid_to" :disabled="data.is_internal === 1 || data.is_internal === true"
                                placeholder="Datum auswählen" class="w-full"></flat-pickr>
                      <small v-if="(data.is_internal === false || data.is_internal === 0) && !data.valid_to">
                          <span class="text-warning">Wenn gefüllt, wird eine Automatisierung gestartet.</span>
                      </small>
                      <div class="float-right" >
                          <span class="pseudo-link d-inline-block mt-2 mr-2" @click="setValidToDateInWeeks(2)">+ 2 Wochen</span>
                          <span class="pseudo-link d-inline-block mt-2 mr-2" @click="setValidToDateInWeeks(3)">+ 3 Wochen</span>
                      </div>

                  </div>
                </div>

                <div class="vx-row mt-4">
                  <div class="vx-col lg:w-full">
                    <label class="vs-input--label">Sprache (de,en)</label>
                    <vs-input class="w-full" type="text" v-validate="'required'" name="language_code"
                              v-model="data.language_code"></vs-input>
                    <span class="text-danger text-sm"
                          v-if="errors.has('language_code')">{{ errors.first('language_code') }}</span>
                  </div>
                </div>
                <div class="vx-row mt-4">
                  <div class="vx-col lg:w-full">
                    <label class="vs-input--label">Interner Kommentar</label>
                    <vs-textarea class="w-full"
                              v-model="data.comment"></vs-textarea>
                  </div>
                </div>
                <br><br>
                Erstellt am: {{ data.created_at |dateTime }} <br>
                Geändert am: {{ data.updated_at |dateTime }} <br>

                <div style="white-space: pre; font-size: 11px;" class="mt-5"  v-if="$route.params.id">
                  <h3>Shopdaten:</h3>

                  {{ data.shop_data }}

                  <vs-button class="ml-3 mb-4 d-block w-full" @click="refreshShopData">Shopdaten neu abrufen</vs-button>
                </div>
              </div>

            </div>
          </div>
        </vx-card>
      </div>
      <div class="vx-col lg:w-1/2 w-full">
        <vx-card title="Response" class="h-full">
          <div class="vx-row">


            TODO!!<br>

            <b>Shopdata:</b><br>

            <div style="white-space: pre;">
              {{ data.activity_log }}
            </div>

          </div>
        </vx-card>
      </div>
    </div>

    <div class="flex mt-8 justify-end">
      <vs-button color="warning" type="border" class="mb-4" @click="onClickDelete">Demoshop löschen
      </vs-button>
      <vs-button color="warning" type="border" class="ml-3 mb-4" :to="{name :'b2b-sellers-demoshop-list'}">Abbrechen
      </vs-button>
      <vs-button class="ml-3 mb-4" :disabled="!validateForm"  @click="onSave" v-if="$route.params.id">Speichern</vs-button>
      <vs-button class="ml-3 mb-4" :disabled="!validateForm"  @click="onCreate" v-else>Demoshop erstellen</vs-button>
    </div>
  </div>
</template>

<script>
import ApiService from "../../../api";
import staticOptions from "../../../mixins/static/options";
import vSelect from 'vue-select'
import CategorySelector from "../../../components/search/CategorySelector";
import TagSelector from "../../../components/search/TagSelector";

import {Quill} from "vue2-editor";
import moment from "moment";
import flatPickr from 'vue-flatpickr-component';
import MgSelect from "@/components/mg-select/MgSelect.vue";

const Block = Quill.import('blots/block');
Block.tagName = 'DIV';
Quill.register(Block, true);

export default {
  components: {MgSelect, TagSelector, CategorySelector, vSelect, flatPickr},
  data() {
    return {
      data: null,
      crmDeals: [],
      fetched: false,
      datePickerConfig: {},
      taxOptions: staticOptions.tax,
      articleTypes: [],
      toolbar: [
        ['bold', 'italic', 'underline'],
        [{'list': 'ordered'}, {'list': 'bullet'}],
        [{'color': []}, "code-block"],
      ],
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.fetchData();
      this.fetchCrmDeals();
    } else {
      this.initializeData()
      this.fetchCrmDeals();
    }

  },
  computed: {
    validate() {
      return this.errors.any()
    },
    validateForm() {
      return !this.errors.any() && this.data.full_name !== '' && this.data.domain !== ''
    },
  },
  methods: {
    initializeData() {
      this.fetched = true;
      this.data = {
        "full_name": "",
        "email": "",
        "domain": "XXXXXXXXXXXX.demoshop.b2b-sellers.com",
        "active": 1,
        "is_internal": 0,
        "auto_reminder": 1,
        "language_code": "de",
        "shop_data": [],
        "activity_log": [],
        "created_at": "",
        "updated_at": "",
        "comment": "",
      }
    },
      fetchData() {
          ApiService.get('b2b-sellers/demoshop/' + this.$route.params.id)
              .then((response) => {
                  console.log(response)
                 if (response.data.status === 'success') {
                      this.fetched = true;
                      this.data = response.data.result;
                      if (this.data.crm_deal_id && !this.data.crm_deal_id.includes('-')) {
                          this.data.crm_deal_id = parseInt(this.data.crm_deal_id);
                      }
                  } else {
                      this.$router.push({name: 'b2b-sellers-demoshop-list'});
                  }
              })
              .catch((error) => {
                  this.$router.push({name: 'b2b-sellers-demoshop-list'});
              })
      },
      fetchCrmDeals() {
          ApiService.get('crm/deals')
              .then((response) => {
                  if (response.data.status === 'success') {
                      this.crmDeals = response.data.result;
                  } else {
                      this.$vs.notify({
                          title: 'Deals vom CRM konnten nicht geladen werden',
                          text: '',
                          iconPack: 'feather',
                          icon: 'icon-alert-circle',
                          color: 'danger'
                      })
                  }
              })
              .catch((error) => {
                  this.$vs.notify({
                      title: 'Deals vom CRM konnten nicht geladen werden',
                      text: '',
                      iconPack: 'feather',
                      icon: 'icon-alert-circle',
                      color: 'danger'
                  })
              })
      },
    onCreate() {

      this.$vs.loading();
      const data = this.data;
      delete data.shop_data;

      ApiService.post('b2b-sellers/demoshop', this.data).then((response) => {
        this.$vs.loading.close();
        if (response.data.result) {
          this.fetchData();
          this.$vs.notify({
            title: 'Erfolgreich',
            text: 'Der Demoshop wurde erfolgreich gespeichert.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          });
          return this.$router.push({name: 'b2b-sellers-demoshop-detail', params: {id: response.data.result.id}})
        }
        this.$vs.notify({
          title: 'Fehlgeschlagen',
          text: 'Konnte nicht erstellt werden.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }).catch((error) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: 'Fehlgeschlagen',
          text: 'Konnte nicht erstellt werden.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      });
    },
    refreshShopData() {

      this.$vs.loading();
      ApiService.get('b2b-sellers/demoshop/'+ this.$route.params.id + '/refreshshopdata').then((response) => {
        this.$vs.loading.close();
        if (response.data.result) {
          this.fetchData();
          this.$vs.notify({
            title: 'Erfolgreich',
            text: 'Erfolgreich abgerufen',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          });
       return
        }
        this.$vs.notify({
          title: 'Fehlgeschlagen',
          text: 'Konnte nicht abgerufen werden.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }).catch((error) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: 'Fehlgeschlagen',
          text: 'Konnte nicht abgerufen werden.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      });
    },
    onSave() {
      if (!this.validateForm) return
      let payload = this.data;
      delete payload.shop_data;
      ApiService.put('b2b-sellers/demoshop/' + this.$route.params.id, payload)
          .then((response) => {
            if (response.data.status === 'success') {
              this.data = response.data.result;
              return this.$vs.notify({
                title: 'Erfolgreich',
                text: 'Wurde erfolgreich bearbeitet',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'success'
              })
            }

            this.$vs.notify({
              title: 'Fehlgeschlagen',
              text: 'Konnte nicht bearbeitet werden.',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          })
          .catch((error) => {
            this.$vs.notify({
              title: 'Fehlgeschlagen',
              text: 'Konnte nicht bearbeitet werden.',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          })
    },
    onClickDelete() {
      ApiService.delete('b2b-sellers/demoshop/' + this.$route.params.id).then((response) => {

        this.fetchData();

        if (response.data.status === 'success') {
          return this.$vs.notify({
            title: 'Erfolgreich',
            text: 'Wurde erfolgreich gelöscht',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          });
        }

        this.$vs.notify({
          title: 'Fehlgeschlagen',
          text: 'Konnte nicht gelöscht werden.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }).catch((error) => {
        this.$vs.notify({
          title: 'Fehlgeschlagen',
          text: 'Konnte nicht gelöscht werden.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
    },
      setDemoDateInDays(days) {
          const currentDate = moment();
          const newDate = currentDate.add(days, 'days');
          this.data.personal_demo_date = newDate.format('YYYY-MM-DD');
      },
      setValidToDateInWeeks(weeks) {
          const currentDate = moment();
          const newDate = currentDate.add(weeks, 'weeks');
          this.data.valid_to = newDate.format('YYYY-MM-DD');
      },
  },
  filters: {
    date(value) {
      return moment(value).format('DD.MM.YYYY');
    },
    dateTime(value) {
      return moment(value).format('DD.MM.YYYY HH:mm') + ' Uhr';
    }
  }

}
</script>


<style lang="scss">
.product-details {
  .quillWrapper {
    .ql-editor {
      //height: 7rem;
      min-height: 6rem;
    }
  }
}
</style>